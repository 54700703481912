import { 
    MainHeadline,
    MainHeadlineWrapper,
    SubHeadlineWrapper,
    SubHeadline,
    SubSubHeadlineWrapper,
    MobileImage,
    LanguagesTextWrapper,
    LanguagesText
} from "../style";
import AppleStoreImage from "../../assets/apple-store.svg"
import QrCodeImage from "../../assets/qr-code.svg"
const DownloadAppSection   = ({ langObject }) => {
    return(
        <div>
            <MainHeadlineWrapper >
                <MainHeadline >{langObject.downloadAppHeading || ""}</MainHeadline>
            </MainHeadlineWrapper>
            <SubHeadlineWrapper >
                <SubHeadline>{langObject.downloadAppSubHeading || ""}</SubHeadline>
            </SubHeadlineWrapper>
            <SubSubHeadlineWrapper >
                <MobileImage src={AppleStoreImage} style={{width:"157px"}} />
                <MobileImage src={QrCodeImage} style={{width:"180px"}} />
            </SubSubHeadlineWrapper>
            <LanguagesTextWrapper>
                <LanguagesText>{langObject.downloadAppText}</LanguagesText>
            </LanguagesTextWrapper>
        </div>
    )
}

export default DownloadAppSection;
