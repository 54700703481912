import styled, { css } from 'styled-components';

export const HeadingWrapper = styled.div`
        width: 30%; 
        margin-top: 80px;
        width: 100%;
        display:flex;
        flex-direction: column;
        align-items:${props => props.align==="left" ? "flex-start" :  props.align==="right" ? "flex-end": "center"};
        ${props => css`
            @media (min-width: 426px) and (max-width: 768px) {
                /* Add your styles for medium screens here */
                margin-top: 60px;
            }
    
            @media (min-width: 376px) and (max-width: 425px) {
                /* Add your styles for medium screens here */
                margin-top: 50px;
            }
    
            @media (max-width: 375px) {
                /* Add your styles for medium screens here */
                margin-top: 40px;
            }
        `}
`;

export const HeadingText = styled.span`
  color: ${props => props.theme.textColor};
  font-family: Arial;
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: linear-gradient(to right, rgba(240, 8, 8, 1), rgba(255, 168, 0, 1), rgba(250, 255, 0, 1), rgba(20, 255, 0, 1), rgba(0, 255, 209, 1), rgba(0, 102, 255, 1));
  background: -webkit-linear-gradient(to right, rgba(240, 8, 8, 1), rgba(255, 168, 0, 1), rgba(250, 255, 0, 1), rgba(20, 255, 0, 1), rgba(0, 255, 209, 1), rgba(0, 102, 255, 1));
  background: -moz-linear-gradient(to right, rgba(240, 8, 8, 1), rgba(255, 168, 0, 1), rgba(250, 255, 0, 1), rgba(20, 255, 0, 1), rgba(0, 255, 209, 1), rgba(0, 102, 255, 1));
  background: -o-linear-gradient(to right, rgba(240, 8, 8, 1), rgba(255, 168, 0, 1), rgba(250, 255, 0, 1), rgba(20, 255, 0, 1), rgba(0, 255, 209, 1), rgba(0, 102, 255, 1));
  background-size: 86% 86%;
  -webkit-background-clip: text;
  -webkit-text-stroke: 1.5px transparent;

  ${props => css`
        @media (min-width: 426px) and (max-width: 768px) {
            /* Add your styles for medium screens here */
            font-size: 64px;
        }

        @media (min-width: 376px) and (max-width: 425px) {
            /* Add your styles for medium screens here */
            font-size: 54px;
        }

        @media (max-width: 375px) {
            /* Add your styles for medium screens here */
            font-size: 48px;
        }
    `}
`;
export const HeadingSubText = styled.span`
    color: ${props => props.theme.textColor};
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    ${props => css`
        @media (min-width: 426px) and (max-width: 768px) {
            /* Add your styles for medium screens here */
            font-size: 20px;
        }

        @media (min-width: 376px) and (max-width: 425px) {
            /* Add your styles for medium screens here */
            font-size: 18px;
        }

        @media (max-width: 375px) {
            /* Add your styles for medium screens here */
            font-size: 16px;
        }
    `}
`;

export const SubHeadingWrapper = styled.div`
        margin-top:80px;
        display:flex;
        justify-content:${props => props.align==="left" ? "flex-start" :  props.align==="right" ? "flex-end": "center"};
        text-align:$${props => props.align==="left" ? "flex-start" :  props.align==="right" ? "flex-end": "center"};
        ${props => css`
            @media (min-width: 426px) and (max-width: 768px) {
                /* Add your styles for medium screens here */
                margin-top: 60px;
            }
    
            @media (min-width: 376px) and (max-width: 425px) {
                /* Add your styles for medium screens here */
                margin-top: 50px;
            }
    
            @media (max-width: 375px) {
                /* Add your styles for medium screens here */
                margin-top: 40px;
            }
        `}
`;

export const SubSubHeadingWrapper = styled.div`
        margin-top:25px;
        display:flex;
        justify-content:${props => props.align==="left" ? "flex-start" :  props.align==="right" ? "flex-end": "center"};
        text-align:$${props => props.align==="left" ? "flex-start" :  props.align==="right" ? "flex-end": "center"};
        ${props => css`
            @media (min-width: 426px) and (max-width: 768px) {
                /* Add your styles for medium screens here */
                margin-top: 25px;
            }
    
            @media (min-width: 376px) and (max-width: 425px) {
                /* Add your styles for medium screens here */
                margin-top: 20px;
            }
    
            @media (max-width: 375px) {
                /* Add your styles for medium screens here */
                margin-top: 12px;
            }
        `}
`;

export const HeadingSubSubText = styled.span`
        color: ${props => props.theme.subHeadingColor};
        font-family: Arial;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        ${props => css`
            @media (min-width: 426px) and (max-width: 768px) {
                /* Add your styles for medium screens here */
                font-size: 18px;
            }
    
            @media (min-width: 376px) and (max-width: 425px) {
                /* Add your styles for medium screens here */
                font-size: 16px;
            }
    
            @media (max-width: 375px) {
                /* Add your styles for medium screens here */
                font-size: 14px;
            }
        `}
`;