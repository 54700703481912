
import Icon from "../assets/icon.svg";
import { BrandingTile, BrandingContainer, BrandIconImage } from "./style";
const HomePageBranding = () => {
    return (
        <BrandingContainer>
            <div className="home-page-branding__logo">
                <BrandIconImage  src={Icon} />
            </div>
        </BrandingContainer>
    )
}
export default HomePageBranding;