import React from "react";
import {
  HeadingWrapper,
  HeadingText,
  SubHeadingWrapper,
  HeadingSubText,
  SubSubHeadingWrapper,
  HeadingSubSubText,
} from "./styles";

const HomePageBanner = ({ langObject, audio }) => {
  return (
    <div>
      <HeadingWrapper>
        <HeadingText>{langObject.headingText1}</HeadingText>
        <HeadingText>{langObject.headingText2}</HeadingText>
        <HeadingText>{langObject.headingText3}</HeadingText>
      </HeadingWrapper>
      <SubHeadingWrapper>
        <HeadingSubText>{langObject.subHeading}</HeadingSubText>
      </SubHeadingWrapper>
      <SubSubHeadingWrapper >
        <HeadingSubSubText>{langObject.subsubHeading}</HeadingSubSubText>
      </SubSubHeadingWrapper>
    </div>
  );
};
export default HomePageBanner;
