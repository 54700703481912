import React from "react";
import { 
    MainHeadline,
    MainHeadlineWrapper,
    SubHeadlineWrapper,
    SubHeadline,
    SubSubHeadlineWrapper,
    MobileImage,
    LanguagesTextWrapper,
    LanguagesText
} from "../style";
import Image from "../../assets/mobile.svg"

const CommitmentSection = ({ langObject, audio }) => {
    return (
        <div>
            <MainHeadlineWrapper>
                <MainHeadline>{langObject.commitmentHeading || ""}</MainHeadline>
            </MainHeadlineWrapper>
            <SubHeadlineWrapper>
                <SubHeadline>{langObject.commitmentSubHeading || ""}</SubHeadline>
            </SubHeadlineWrapper>
            <SubSubHeadlineWrapper >
                <MobileImage src={Image} />
            </SubSubHeadlineWrapper>
            <LanguagesTextWrapper>
                <LanguagesText>{langObject.languagesText}</LanguagesText>
            </LanguagesTextWrapper>
        </div>
    );
}

export default CommitmentSection;
