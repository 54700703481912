import React from "react";
import {
    StyledSpan,
    StyledDiv,
    StyledLine
} from "./styles";

const LinesComponent = () => {
  return (
    <>
      <div>
        {new Array(46).fill(0).map((_, index) => (
          <StyledLine
          height={index % 2 === 0
            ? `${(46 - index) / 2}px`
            : `${parseInt(index / 2) + 1}px`}
            isBlack={index % 2 !== 0}
          ></StyledLine>
        ))}
      </div>
      <StyledDiv
      >
        <StyledSpan>easyConsent®</StyledSpan>
        <StyledSpan>DPMA Registered Trademark</StyledSpan>
        <StyledSpan>Nr. 30 2022 234 045</StyledSpan>
        <StyledSpan style={{ marginTop:"10px"}}>VIZION GAMES STUDIOS SL</StyledSpan>
        <StyledSpan>© 2023 - 2024</StyledSpan>
      </StyledDiv>
    </>
  );
};

export default LinesComponent;
