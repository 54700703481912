import styled, { css } from "styled-components";
import { textAlign } from "../../constants";

export const ContactSectionWrapper = styled.div`
  margin-top: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => css`
    @media (min-width: 1440px) and (max-width: 1919px) {
      /* Add your styles for medium screens here */
      margin-top: 36px;
    }

    @media (min-width: 1366px) and (max-width: 1440px) {
      /* Add your styles for medium screens here */
      margin-top: 36px;
    }

    @media (min-width: 1280px) and (max-width: 1366px) {
      /* Add your styles for medium screens here */
      margin-top: 34px;
    }

    @media (min-width: 1024px) and (max-width: 1280px) {
      /* Add your styles for medium screens here */
      margin-top: 32px;
    }

    @media (min-width: 800px) and (max-width: 1024px) {
      /* Add your styles for medium screens here */
      margin-top: 30px;
    }

    @media (min-width: 768px) and (max-width: 800px) {
      /* Add your styles for medium screens here */
      margin-top: 30px;
    }

    @media (min-width: 600px) and (max-width: 768px) {
      /* Add your styles for medium screens here */
      margin-top: 24px;
    }

    @media (min-width: 480px) and (max-width: 600px) {
      /* Add your styles for medium screens here */
      margin-top: 27px;
    }

    @media (min-width: 414px) and (max-width: 480px) {
      /* Add your styles for medium screens here */
      margin-top: 27px;
    }

    @media (min-width: 375px) and (max-width: 414px) {
      /* Add your styles for medium screens here */
      margin-top: 25px;
    }

    @media (min-width: 360px) and (max-width: 375px) {
      /* Add your styles for medium screens here */
      margin-top: 25px;
    }

    @media (min-width: 320px) and (max-width: 360px) {
      /* Add your styles for medium screens here */
      margin-top: 25px;
    }
  `};
`;

export const StyledInput = styled.input`
  width: 545px;
  height: 34px;
  background-color: black;
  border: 0.5px solid #aaa;
  border-radius: 10px;
  margin-top: ${(props) => props.customMargin};
  text-align: ${props => textAlign[props.align]};

  color: #aaa;
  font-family: Arial;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 18px 16px;

  &::placeholder {
    color: #aaa;
    font-family: Arial;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: ${props => textAlign[props.align]};
  }

  ${(props) => css`
    @media (min-width: 1440px) and (max-width: 1919px) {
      /* Add your styles for medium screens here */
      width: 545px;

      &::placeholder {
        font-size: 28px;
      }
    }

    @media (min-width: 1366px) and (max-width: 1440px) {
      /* Add your styles for medium screens here */
      width: 520px;

      &::placeholder {
        font-size: 28px;
      }
    }

    @media (min-width: 1280px) and (max-width: 1366px) {
      /* Add your styles for medium screens here */
      width: 520px;

      &::placeholder {
        font-size: 28px;
      }
    }

    @media (min-width: 1024px) and (max-width: 1280px) {
      /* Add your styles for medium screens here */
      width: 520px;

      &::placeholder {
        font-size: 26px;
      }
    }

    @media (min-width: 800px) and (max-width: 1024px) {
      /* Add your styles for medium screens here */
      width: 520px;

      &::placeholder {
        font-size: 24px;
      }
    }

    @media (min-width: 768px) and (max-width: 800px) {
      /* Add your styles for medium screens here */
      width: 520px;

      &::placeholder {
        font-size: 24px;
      }
    }

    @media (min-width: 600px) and (max-width: 768px) {
      /* Add your styles for medium screens here */
      width: 520px;

      &::placeholder {
        font-size: 22px;
      }
    }

    @media (min-width: 480px) and (max-width: 600px) {
      /* Add your styles for medium screens here */
      width: 400px;

      &::placeholder {
        font-size: 20px;
      }
    }

    @media (min-width: 414px) and (max-width: 480px) {
      /* Add your styles for medium screens here */
      width: 342px;

      &::placeholder {
        font-size: 20px;
      }
    }

    @media (min-width: 375px) and (max-width: 414px) {
      /* Add your styles for medium screens here */
      width: 311px;

      &::placeholder {
        font-size: 20px;
      }
    }

    @media (min-width: 360px) and (max-width: 375px) {
      /* Add your styles for medium screens here */
      width: 296px;

      &::placeholder {
        font-size: 18px;
      }
    }

    @media (min-width: 320px) and (max-width: 360px) {
      /* Add your styles for medium screens here */
      width: 256px;

      &::placeholder {
        font-size: 18px;
      }
    }
  `};
`;

export const StyledTextArea = styled.textarea`
  padding: 18px 16px;
  color: #aaa;
  font-family: Arial;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 545px;
  height: 174px;
  background-color: black;
  margin-top: ${(props) => props.customMargin};
  text-align: ${props => textAlign[props.align]};
  border-radius: 10px;

  &::placeholder {
    color: #aaa;
    font-family: Arial;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: ${props => textAlign[props.align]}
  }

  ${(props) => css`
    @media (min-width: 1440px) and (max-width: 1919px) {
      /* Add your styles for medium screens here */
      width: 545px;
      height: 174px;

      &::placeholder {
        font-size: 28px;
      }
    }

    @media (min-width: 1366px) and (max-width: 1440px) {
      /* Add your styles for medium screens here */
      width: 520px;
      height: 174px;

      &::placeholder {
        font-size: 28px;
      }
    }

    @media (min-width: 1280px) and (max-width: 1366px) {
      /* Add your styles for medium screens here */
      width: 520px;
      height: 174px;

      &::placeholder {
        font-size: 28px;
      }
    }

    @media (min-width: 1024px) and (max-width: 1280px) {
      /* Add your styles for medium screens here */
      width: 520px;
      height: 174px;

      &::placeholder {
        font-size: 26px;
      }
    }

    @media (min-width: 800px) and (max-width: 1024px) {
      /* Add your styles for medium screens here */
      width: 520px;
      height: 174px;

      &::placeholder {
        font-size: 24px;
      }
    }

    @media (min-width: 768px) and (max-width: 800px) {
      /* Add your styles for medium screens here */
      width: 520px;
      height: 174px;

      &::placeholder {
        font-size: 24px;
      }
    }

    @media (min-width: 600px) and (max-width: 768px) {
      /* Add your styles for medium screens here */
      width: 520px;
      height: 174px;

      &::placeholder {
        font-size: 22px;
      }
    }

    @media (min-width: 480px) and (max-width: 600px) {
      /* Add your styles for medium screens here */
      width: 400px;
      height: 174px;

      &::placeholder {
        font-size: 20px;
      }
    }

    @media (min-width: 414px) and (max-width: 480px) {
      /* Add your styles for medium screens here */
      width: 342px;
      height: 174px;

      &::placeholder {
        font-size: 20px;
      }
    }

    @media (min-width: 375px) and (max-width: 414px) {
      /* Add your styles for medium screens here */
      width: 311px;
      height: 174px;

      &::placeholder {
        font-size: 20px;
      }
    }

    @media (min-width: 360px) and (max-width: 375px) {
      /* Add your styles for medium screens here */
      width: 296px;
      height: 154px;

      &::placeholder {
        font-size: 18px;
      }
    }

    @media (min-width: 320px) and (max-width: 360px) {
      /* Add your styles for medium screens here */
      width: 256px;
      height: 154px;

      &::placeholder {
        font-size: 18px;
      }
    }
  `};
`;

export const StyledButton = styled.a`
  width: 340px;
  height: 50px;
  background-color: black;
  border: 0.5px solid #aaa;
  border-radius: 10px;
  margin-top: ${(props) => props.customMargin};
  text-decoration: none;

  color: #fff;
  text-align: center;
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display:flex;
  align-items:center;
  justify-content:center;

  ${(props) => css`
    @media (min-width: 1440px) and (max-width: 1919px) {
      /* Add your styles for medium screens here */
      width: 340px;
      font-size: 18px;
    }

    @media (min-width: 1366px) and (max-width: 1440px) {
      /* Add your styles for medium screens here */
      width: 340px;
      font-size: 18px;
    }

    @media (min-width: 1280px) and (max-width: 1366px) {
      /* Add your styles for medium screens here */
      width: 340px;
      font-size: 18px;
    }

    @media (min-width: 1024px) and (max-width: 1280px) {
      /* Add your styles for medium screens here */
      width: 340px;
      font-size: 18px;
    }

    @media (min-width: 800px) and (max-width: 1024px) {
      /* Add your styles for medium screens here */
      width: 340px;
      font-size: 18px;
    }

    @media (min-width: 768px) and (max-width: 800px) {
      /* Add your styles for medium screens here */
      width: 340px;
      font-size: 18px;
    }

    @media (min-width: 600px) and (max-width: 768px) {
      /* Add your styles for medium screens here */
      width: 340px;
      font-size: 18px;
    }

    @media (min-width: 480px) and (max-width: 600px) {
      /* Add your styles for medium screens here */
      width: 280px;
      font-size: 18px;
    }
    @media (min-width: 430px) and (max-width: 480px) {
      /* Add your styles for medium screens here */
      width: 280px;
      font-size: 18px;
    }

    @media (min-width: 414px) and (max-width: 430px) {
      /* Add your styles for medium screens here */
      width: 280px;
      font-size: 18px;
    }

    @media (min-width: 375px) and (max-width: 414px) {
      /* Add your styles for medium screens here */
      width: 280px;
      font-size: 18px;
    }

    @media (min-width: 360px) and (max-width: 375px) {
      /* Add your styles for medium screens here */
      width: 280px;
      font-size: 18px;
    }

    @media (min-width: 320px) and (max-width: 360px) {
      /* Add your styles for medium screens here */
      width: 220px;
      font-size: 16px;
    }
  `};
`;

export const PrivacyContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    margin-top:40px;

  //   ${(props) => css`
  //   @media (min-width: 1440px) and (max-width: 1919px) {
  //     /* Add your styles for medium screens here */
  //     margin-top:25px;
  //   }

  //   @media (min-width: 1366px) and (max-width: 1440px) {
  //     /* Add your styles for medium screens here */
  //     margin-top:25px;
  //   }

  //   @media (min-width: 1280px) and (max-width: 1366px) {
  //     /* Add your styles for medium screens here */
  //     margin-top:25px;
  //   }

  //   @media (min-width: 1024px) and (max-width: 1280px) {
  //     /* Add your styles for medium screens here */
  //     margin-top:25px;
  //   }

  //   @media (min-width: 800px) and (max-width: 1024px) {
  //     /* Add your styles for medium screens here */
  //     margin-top:20px;
  //   }

  //   @media (min-width: 768px) and (max-width: 800px) {
  //     /* Add your styles for medium screens here */
  //     margin-top:20px;
  //   }

  //   @media (min-width: 600px) and (max-width: 768px) {
  //     /* Add your styles for medium screens here */
  //     margin-top:20px;
  //   }

  //   @media (min-width: 480px) and (max-width: 600px) {
  //     /* Add your styles for medium screens here */
  //     margin-top:15px;
  //   }

  //   @media (min-width: 414px) and (max-width: 480px) {
  //     /* Add your styles for medium screens here */
  //     margin-top: 15px;
  //   }

  //   @media (min-width: 375px) and (max-width: 414px) {
  //     /* Add your styles for medium screens here */
  //     margin-top: 15px;
  //   }

  //   @media (min-width: 360px) and (max-width: 375px) {
  //     /* Add your styles for medium screens here */
  //     margin-top: 15px;
  //   }

  //   @media (min-width: 320px) and (max-width: 360px) {
  //     /* Add your styles for medium screens here */
  //     margin-top: 15px;
  //   }
  // `};
`;

export const PrivacyTextWrapper = styled.div`
  width: 577px;
  text-align: center;

  ${(props) => css`
    @media (min-width: 1440px) and (max-width: 1919px) {
      /* Add your styles for medium screens here */
      width: 577px;
    }

    @media (min-width: 1366px) and (max-width: 1440px) {
      /* Add your styles for medium screens here */
      width: 552px;
    }

    @media (min-width: 1280px) and (max-width: 1366px) {
      /* Add your styles for medium screens here */
      width: 552px;
    }

    @media (min-width: 1024px) and (max-width: 1280px) {
      /* Add your styles for medium screens here */
      width: 552px;
    }

    @media (min-width: 800px) and (max-width: 1024px) {
      /* Add your styles for medium screens here */
      width: 552px;
    }

    @media (min-width: 768px) and (max-width: 800px) {
      /* Add your styles for medium screens here */
      width: 552px;
    }

    @media (min-width: 600px) and (max-width: 768px) {
      /* Add your styles for medium screens here */
      width: 552px;
    }

    @media (min-width: 480px) and (max-width: 600px) {
      /* Add your styles for medium screens here */
      width: 432px;
    }

    @media (min-width: 414px) and (max-width: 480px) {
      /* Add your styles for medium screens here */
      width: 374px;
    }

    @media (min-width: 375px) and (max-width: 414px) {
      /* Add your styles for medium screens here */
      width: 343px;
    }

    @media (min-width: 360px) and (max-width: 375px) {
      /* Add your styles for medium screens here */
      width: 328px;
    }

    @media (min-width: 320px) and (max-width: 360px) {
      /* Add your styles for medium screens here */
      width: 288px;
    }
  `};
`;

export const PrivacyText = styled.span`
  color: black;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.4px;

  ${(props) => css`
    @media (min-width: 426px) and (max-width: 768px) {
      /* Add your styles for medium screens here */
      font-size: 14px;
      line-height: 16.1px;
    }

    @media (min-width: 376px) and (max-width: 425px) {
      /* Add your styles for medium screens here */
      font-size: 12px;
      line-height: 13.8px;
    }

    @media (max-width: 375px) {
      /* Add your styles for medium screens here */
      font-size: 10px;
      line-height: 11.5px;
    }
  `}
`;
