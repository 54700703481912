import React from "react";
import { PageContainer } from "./style";
import Navbar from "./navbar";
import RightsSection from "./RightsSection";
import LinesSection from "./LinesSection";
import HomePageBranding from "./HomePageBranding";

const PageWrapper = ({ children, page }) => {
  return (
    <>
      <PageContainer>
        <Navbar page={page} />
        <HomePageBranding />
        {children}
        {/* <RightsSection /> */}
      </PageContainer>
      <LinesSection />
    </>
  );
};

export default PageWrapper;
