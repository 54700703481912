import React from "react";
import { StyledLink, LinkWrapper } from "./styles";

import {
  MainHeadline,
  MainHeadlineWrapper,
  SubHeadlineWrapper,
  SubHeadline,
} from "../style";

const LegalSection = ({ langObject, audio }) => {
  return (
    <>
      <MainHeadlineWrapper>
        <MainHeadline >{langObject.legalInformationHeadline}</MainHeadline>
      </MainHeadlineWrapper>
      <SubHeadlineWrapper >
        <SubHeadline >
          {langObject.legalInformationSubHeadline}
        </SubHeadline>
      </SubHeadlineWrapper>
      <LinkWrapper >
        <StyledLink  to={"/terms"} margin={0}>{langObject.termsOfUseText}</StyledLink>
        <StyledLink  to={"/privacy-policy"} margin={32}>{langObject.privacyPolicyText}</StyledLink>
        <StyledLink  to={"/cookie-policy"} margin={32}>{langObject.cookiePolicy}</StyledLink>
      </LinkWrapper>
    </>
  );
};

export default LegalSection;
